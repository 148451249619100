@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media print {
  input[type="radio"] {
    appearance: auto;
    background: none;
  }
}

.lang-picker {
  color: #555;
  background-color: #fff;
  border: none;
  padding: 10px 0;
  display: block;
}

.lang-picker:focus {
  outline: none;
}

.top-bar {
  justify-content: center;
  height: 100px;
  display: flex;
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px -1px #0000001a;
}

.top-bar-inner {
  width: var(--max-width);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.top-bar-logo {
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo-bar {
  margin: auto;
  margin-inline: 10px;
  font-size: 30px;
}

.top-bar-logo img {
  border-right: 1px solid #d3d3d3;
  width: 90px;
  padding-right: 10px;
}

.top-bar-logo h2 {
  color: var(--primary-blue);
  padding-left: 10px;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-ext-400-normal.5baf44ce.woff2") format("woff2"), url("roboto-cyrillic-ext-400-normal.a08b2a3e.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-400-normal.6d360dd5.woff2") format("woff2"), url("roboto-cyrillic-400-normal.a5a342c2.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-ext-400-normal.3b199b49.woff2") format("woff2"), url("roboto-greek-ext-400-normal.e526b0c1.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-400-normal.94705851.woff2") format("woff2"), url("roboto-greek-400-normal.8ad61867.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-vietnamese-400-normal.b7170924.woff2") format("woff2"), url("roboto-vietnamese-400-normal.80131064.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-ext-400-normal.65ba6c38.woff2") format("woff2"), url("roboto-latin-ext-400-normal.7013a0e5.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.57844c26.woff2") format("woff2"), url("roboto-latin-400-normal.0a2ad554.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-blue: #00bef0;
  --dark-blue: #006ebd;
  --bg-blue: #e5f0f8;
  --light-grey: #dde2e3;
  --text-light-grey: #84959a;
  --text-grey: #556468;
  --max-width: 560px;
  --success-bg: #e6f1ee;
  --success-text: #0d7758;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  flex-direction: column;
  min-height: 100vh;
  font-family: Roboto, Arial, sans-serif;
  display: flex;
}

body {
  margin-inline: auto;
  font-family: Roboto;
}

p {
  color: var(--text-grey);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

h1 {
  color: var(--text-grey);
  margin: 0;
  margin-top: 24px;
  margin-bottom: 32px;
  font-size: 35px;
  font-weight: 400;
}

h3 {
  color: var(--text-grey);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

hr {
  border: 1px solid var(--light-grey);
  margin-block: 32px;
}

.logo {
  max-width: 240px;
}

.content {
  width: var(--max-width);
  text-align: left;
  flex-direction: column;
  flex: 1;
  margin: 40px auto;
  display: flex;
}

label {
  color: var(--text-grey);
  white-space: pre-wrap;
  margin-top: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: inline-block;
}

input, textarea {
  color: #556468;
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

input:focus, textarea:focus {
  border-color: #00bef0;
  outline: none;
}

input[type="checkbox"] {
  cursor: pointer;
  border: 2px solid;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  margin: 0 10px;
  display: inline-block;
  position: relative;
  top: 2px;
}

input[type="file"] {
  border: none;
  padding-left: 0;
}

input[type="checkbox"]:hover {
  border-color: #0095bd;
}

input[type="checkbox"]:checked {
  background-color: #0095bd;
  border-color: #0095bd;
}

.asterisk {
  color: red;
}

.bold {
  font-weight: 700;
}

.button {
  cursor: pointer;
  border: 0;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
}

.primary-button {
  background: var(--primary-blue);
  color: #fff;
  width: 100%;
  padding: 10px 20px;
}

.secondary-button {
  border: 2px solid var(--primary-blue);
  width: 200px;
  height: 40px;
  color: var(--primary-blue);
  background-color: #fff;
}

.button span {
  margin-top: 4px;
  padding-right: 5px;
}

.informational {
  background-color: var(--bg-blue);
  color: var(--dark-blue);
  border-radius: 5px;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 40px;
  padding: 20px;
  display: flex;
}

.information-icon {
  width: 24px;
  height: 24px;
}

.loading-spinner {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -100px;
  display: flex;
}

.loading-spinner img {
  width: 32px;
  margin-bottom: 16px;
  animation: 2s linear infinite spin;
}

.pr-selector {
  margin-bottom: 32px;
  display: block;
}

.pr-selector label {
  margin-right: 5px;
}

.success-message {
  background-color: var(--success-bg);
  color: var(--success-text);
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  padding: 24px;
  display: flex;
}

.success-message p {
  font-weight: 700;
}

.success-message img {
  width: 30px;
  margin-right: 16px;
}

.file-input {
  display: inline-block;
}

.hidden {
  display: none;
}

.label-info {
  color: var(--text-light-grey);
  font-weight: 400;
}

.form-data {
  white-space: pre-wrap;
}

.file-name {
  color: #4a5568;
  border-bottom: 1px solid #00bef0;
  border-radius: 4px;
  margin-right: 8px;
  padding: 4px 8px;
}

.file-delete-icon {
  cursor: pointer;
  color: #718096;
  margin-inline: 8px;
  font-size: 12px;
}

.file-delete-icon:hover {
  color: red;
}

textarea {
  width: 100%;
}

select {
  box-sizing: border-box;
  color: #555;
  cursor: pointer;
  background: #fff;
  border: none;
  width: fit-content;
  padding: 10px 0;
  display: inline;
}

select:focus {
  outline: none;
}

.back-button {
  cursor: pointer;
  font-size: 30px;
}

.relation-delete-icon {
  float: right;
  cursor: pointer;
  margin-top: -24px;
}

.add-file-btn {
  color: var(--primary-blue);
  text-align: end;
  cursor: pointer;
  flex-direction: row;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  display: inline-flex;
}

.add-file-btn img {
  margin-top: -2.5px;
}

input[type="radio"] {
  --size: 1.3em;
  --color: var(--primary-blue);
  height: var(--size);
  width: var(--size);
  aspect-ratio: 1;
  border: calc(var(--size) / 8) solid #939393;
  padding: calc(var(--size) / 8);
  background: radial-gradient(farthest-side, var(--color) 94%, #0000) 50% / 0 0 no-repeat content-box;
  outline-offset: calc(var(--size) / 10);
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  border-radius: 50%;
  transition: all .3s;
}

input[type="radio"]:checked {
  border-color: var(--color);
  background-size: 100% 100%;
}

@media print {
  input[type="radio"] {
    appearance: auto;
    background: none;
  }
}

.radio-btns {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.radio-btn {
  cursor: pointer;
  gap: 10px;
  margin: 5px 0;
  display: flex;
}

.radio-btn p {
  margin-top: -2px;
}

.login-message {
  margin-top: 20px;
}
/*# sourceMappingURL=index.53fd0e8c.css.map */
